.c__tooltip {
  position: relative;
  display: inline-block;
}

.c__tooltip .tooltiptext {
  visibility: hidden;
  /* white-space: pre-wrap; */
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 1rem;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: -50%;
  /* transform: translateX(-50%); */
  min-width: 10vw;
  max-width: 12vw;
}


.c__tooltip:hover .tooltiptext {
  visibility: visible;
}
