:root {
  --button-border: #d9d9d9;
  --green: #7db00e;
  --muted: #424e56;
  --bootstrap-muted: #DEE2E6
}

/* utilities  */
.floating-button {
  position: fixed;
  cursor: pointer;
  z-index: 999;

  padding: 1rem;
  padding-left: .2rem;
  background-color: #012939;
  border: 2px solid white;
  border-radius: 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
}

.color-picker {
  border: none;
}


.bg-circle {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
}

.borderTable td {
  border-right: 1px solid var(--bootstrap-muted);
  /* Vertical border for most cells */
}

.color__picker__circle {
  position: fixed;
  top: 61px;
  z-index: 222;
}

.active__circle {
  border: 2px solid white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease-out;
}

.pointer {
  cursor: pointer;
}

.text-green {
  color: var(--green);
}

.animate {
  cursor: pointer;
  transition: transform 0.5s ease;
}

.animate:hover {
  transform: scale(1.2);
}

.bghoverwhite:hover {
  background-color: white;
  border-radius: 10px;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

/* columns  */

@media (min-width: 992px) {

  /* Adjust the min-width as per your requirement for 'larger screens' */
  .column-22 {
    width: 22%;
    float: left;
    /* Ensures columns are side by side */
  }

  .column-78 {
    width: 78%;
    float: left;
  }
}

.badge__primary {
  background-color: #eaeaea;
  color: #000000;
}

/* animation */
.toggle-button {
  cursor: pointer;
  user-select: none;
}

.drag__handle {
  width: 100%;
  height: 100%;
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.slide-in {
  max-height: 5000px;
}

.slide-out {
  max-height: 0;
}

.P__relative {
  position: relative;
}

.p__absolute {
  position: absolute;
}

/* left to right animation */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

/* tags  */
.tag__container {
  display: flex;
  align-items: center;
  /* Aligns children (image-container and text-right) vertically center */
}

.tag__image-container {
  position: relative;
  display: inline-block;
  /* Allows the container to fit the size of the image */
}

.tag__text-over-image {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 90%;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  /* transform: translate(-20%); */
}

.tag__sec__text-over-image {
  position: absolute;
  top: 90%;
  left: 92%;
  transform: translate(-50%, -55%);
  text-align: center;
  max-width: 90%;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  /* transform: translate(-20%); */
}

.tag__text-right {
  margin-left: 20px;
  /* Spacing between image and text */
  /* Add more styling as needed */
}

/* bootstrap pagination */
.pagination .page-link {
  color: var(--green);
  /* Custom text color */
  background-color: transparent;
  /* Transparent background */
  border-color: var(--green);
  /* Custom border color */
}

.pagination .page-item.active .page-link {
  color: white;
  /* Text color for active page */
  background-color: var(--green);
  /* Background color for active page */
  border-color: var(--green);
  /* Border color for active page */
}

.icon-container {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-left: 5px;
}

.icon-up,
.icon-down {
  display: block;
  color: #ccc;
  /* Default color */
  font-size: 1.2rem;
}

.icon-active {
  color: #000;
  /* Highlight color for active sort direction */
}

.icon-down {
  position: absolute;
  top: 100%;
  margin-top: -0.8rem;
}

/* anchor tag  */
.style__none {
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.muted {
  color: var(--muted) !important;
}

.non-clickable {
  pointer-events: none;
  /* This prevents the link from being clickable */
  color: grey;
  /* Optional: change the color to indicate it is not active */
  text-decoration: none;
  /* Optional: removes the underline to indicate it is not a link */
}

/* margin and padding */
.mt-n1 {
  margin-top: -1rem !important;
}

.pt-1rem {
  padding-top: 1rem !important;
}

/* table styling */

table td {
  color: var(--muted) !important;
}

.sticky__tag__category {
  background-color: white;
  position: sticky;
  top: 65px;
}

.tag__category--sticky {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
}

.search__sticky {
  position: sticky;
  top: 63px;
  position: -webkit-sticky;
  background-color: white;
  z-index: 1000;
}

.clienttable__sticky {
  position: sticky;
  top: 100px;
  position: -webkit-sticky;
  background-color: white;
  
}

.sticky {
  position: -webkit-sticky;
  background-color: white;
  position: sticky;
}

.tab__sticky {
  /* top: 11.3rem; */
  top: 4rem;
}

.z10 {
  z-index: 10 !important;
}

.dropdown__sticky {
  position: sticky;
  top: 12rem;
  position: -webkit-sticky;
  background-color: white;
  z-index: 1000;
}

table tr td {
  padding: 1rem;
  vertical-align: middle;
}

.stable thead tr{
  position: sticky !important;
  background-color: white !important;
  position: -webkit-sticky;
  top: 11.3rem !important;
  z-index: 0;
  /* top: 0; */
}

.advanceTable thead tr {
  top: 7rem !important;
  /* top: 15rem !important; */
  z-index: 0;
}

.closedJob thead tr {
  top: 3.95rem !important;
}

.table__div {
  overflow: auto;
  /* height: 500px; */
}

table th {
  color: var(--muted) !important;
  font-weight: 400 !important;
}

/* scrollbar  */
.hide__scrollbar {
  overflow: auto;
  /* Enables vertical scrolling */
  scrollbar-width: none;
  /* Hides the scrollbar for Firefox */

  /* Hides the scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  /* Fixed position */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: background color with some transparency */
  z-index: 9999;
  /* High z-index to overlay content */
}

.datepicker {
  z-index: 99;
  right: 0;
  position: absolute;
  top: 4rem;
  border: 1px solid var(--button-border);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.visit__select select {
  width: 5rem !important;
}

/* Tabs  */
.custom-tab .nav-link {
  color: #666 !important;
  /* Inactive text color */
}

.custom-tab li:not(:first-child):not(:last-child) .nav-link:hover .edit-button {
  visibility: visible;
  opacity: 1;
}

.custom-tab .nav-link.active {
  color: var(--green) !important;
  /* Active text color */
  border-color: var(--green) !important;
}

.attachement {
  border: 1px solid var(--bootstrap-muted);
  border-radius: 5px;
  padding: 8px;
}

.fs-12 {
  font-size: 12px;
}

.truncate-text {
  width: 50px;
  /* Adjust the width as needed */
  white-space: nowrap;
  /* Ensures the text is on a single line */
  overflow: hidden;
  /* Hide the overflow text */
  text-overflow: ellipsis;
  /* Add ellipsis at the end of the text */
}

.recycle-custom-tab .nav-link {
  color: #666 !important;
  /* Inactive text color */
}

.recycle-custom-tab .nav-link.active {
  color: var(--green) !important;
  /* Active text color */
  border-color: var(--green) !important;
}

.tab__sticky .tab-content {
  display: none !important;
}

.custom-tab-title {
  position: relative;
}

.edit-button {
  position: absolute;
  top: -1.2rem;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.edit-button svg {
  color: var(--green);
}

.remain__on__top {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

/* .child__style__select select{
  padding: 0 !important;  
  height: 25px !important;
}

.child__style__select svg{
  font-size: 10px !important;  
} */

.notetable tr td {
  vertical-align: top;
}

.card__initial {
  border-left: 6px solid green !important;
  background-color: #F0EFEA !important;
}

.card__initial:hover {
  background-color: transparent !important;
}

.card__after {
  border: 6px solid green !important;
  background-color: #c1f7c4 !important;
}

.applied__filters>.wSI5L-5rODg-:hover {
  background-color: #EEF0F2 !important;
  --chip--gradient-color-variation--hover: initial !important;
  cursor: initial;
}

.th--border th:not(:first-child) {
  border-left: 1px solid #DEE2E6;
  padding: 0.5rem;
  /* Adjust padding to control the height */
  vertical-align: middle;
  /* Ensure text is vertically centered */
}

.th--border th {
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Add ellipsis for overflowed text */
}

.no-sidebar .rdrDefinedRangesWrapper {
  display: none;
}

.no-sidebar .rdrCalendarWrapper {
  width: 100% !important;
}

.automation__filter p {
  text-wrap: nowrap;
}

.sync__links span:hover {
  background-color: var(--green);
  color: white;
}
