@media all {
  .genrs_dialog_holder {
    font-size: 14px;
    color: black;
  }
  .genrs_dialog_holder a {
    color: black;
  }
  .genrs_dialog_holder input[type='button'] {
    font: small/normal Arial, sans-serif;
    background: #f5f5f5;
    color: #444;
    border: 1px solid #ccc;
    font-size: 11px;
    font-weight: bold;
    height: 27px;
    line-height: 27px;
    outline: none;
    padding: 0 8px;
    text-align: center;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: relative;
    background-image: -webkit-linear-gradient(top, #f5f5f5, #f1f1f1);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f5f5f5), to(#f1f1f1));
    background-image: -moz-linear-gradient(top, #f5f5f5, #f1f1f1);
    background-image: -ms-linear-gradient(top, #f5f5f5, #f1f1f1);
    background-image: -o-linear-gradient(top, #f5f5f5, #f1f1f1);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#f5f5f5',EndColorStr='#f1f1f1');
  }
  .genrs_dialog_holder input[type='button']:hover {
    border-color: #aaa;
    color: #222;
    background-color: #f9f9f9;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }
  .genrs_dialog_holder input[type='button']:focus {
    border-color: #1e90ff;
  }
  .genrs_dialog_holder input[type='button']:active {
    border-color: #1e90ff;
  }
  .genrs_dialog_holder {
    font-family: helvetica, arial, 'san-serif';
    color: #222;
    font-size: 12px;
  }
  .genrs_dialog_holder {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding-left: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 50;
  }
  .genrs_dialog_holder .genrs_dialog {
    background-color: #f6f6f6;
    border: 1px solid #acacac;
    -webkit-box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);
    -o-box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    display: inline-block;
    min-width: 200px;
    margin-left: -125px;
    overflow: hidden;
    position: relative;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content {
    padding: 10px;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content h1 {
    font-size: 16px;
    padding: 0px;
    margin: 0 0 10px 0;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content h1 a {
    float: right;
    display: inline-block;
    height: 16px;
    width: 16px;
    background-image: url(https://d3ey4dbjkt2f6s.cloudfront.net/assets/recurring_select/cancel-9bd65bf935897e779ac39efbecc48e16546b64316d60eccded14782a95451637.png);
    background-position: center;
    background-repeat: no-repeat;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content p {
    padding: 5px 0;
    margin: 0;
    line-height: 14px;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content p label {
    margin-right: 10px;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content a {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section {
    display: none;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section label {
    font-weight: bold;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .genrs_interval {
    width: 30px;
    text-align: center;
    display: inline-block;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .day_holder {
    border-left: 1px solid #ccc;
    position: relative;
    margin-top: 5px;
    height: 26px;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .day_holder a {
    display: block;
    padding: 5px 7px;
    font-size: 14px;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px 1px 1px 0px;
    float: left;
    text-decoration: none;
    font-weight: bold;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
    -ms-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
    -o-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
    background-color: #fff;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .day_holder a.selected {
    background-color: #89a;
    color: #fff;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
    -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
    -ms-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
    -o-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
    position: relative;
    background-image: -webkit-linear-gradient(top, #9ab, #789);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#9ab), to(#789));
    background-image: -moz-linear-gradient(top, #9ab, #789);
    background-image: -ms-linear-gradient(top, #9ab, #789);
    background-image: -o-linear-gradient(top, #9ab, #789);
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .day_holder a:hover {
    cursor: pointer;
    background-color: #dde;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .genrs_calendar_day,
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .genrs_calendar_week {
    width: 155px;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .genrs_calendar_week {
    width: 191px;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .freq_option_section .monthly_rule_type span {
    margin-right: 15px;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .genrs_summary {
    padding: 0px;
    margin-top: 15px;
    border-top: 1px solid #ccc;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .genrs_summary span {
    font-weight: bold;
    border-top: 1px solid #fff;
    display: block;
    padding: 10px 0 5px 0;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .controls {
    padding: 10px 0px 5px 0px;
    min-width: 170px;
    text-align: center;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .controls input[type='button'] {
    margin: 0px 5px;
    width: 70px;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .controls input.genrs_save[type='button'] {
    color: #333;
  }
  .genrs_dialog_holder .genrs_dialog .genrs_dialog_content .controls input.genrs_cancel[type='button'] {
    color: #666;
  }
  .gen h1,
  .gen a {
    margin: 0;
    padding: 0;
  }
  .gen input,
  .gen select {
    font-family: var(--typography--fontFamily-normal);
  }
  .gen h1 {
    margin: 0;
    font-weight: 700;
    color: var(--color-heading);
    -webkit-font-smoothing: antialiased;
  }
  .gen h1 {
    margin-bottom: var(--space-large);
    font-size: var(--typography--fontSize-jumbo);
    font-family: var(--typography--fontFamily-display);
    font-weight: 900;
    line-height: 1.11em;
  }
  .gen p {
    margin: 0 0 var(--space-base);
    font-size: var(--typography--fontSize-base);
    line-height: 1.25em;
    color: var(--color-text);
  }
  .gen a {
    color: var(--color-interactive);
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
  }
  .gen [type='button'] {
    padding: 0;
    margin: 0;
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    text-align: inherit;
  }
  .gen a {
    -webkit-transition-property: color, background-color, outline;
    transition-property: color, background-color, outline;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }
  .gen a:hover {
    color: var(--color-interactive--hover);
    cursor: pointer;
  }
  .gen a:focus {
    color: var(--color-interactive--hover);
    background-color: var(--color-surface--hover);
    outline: 4px auto var(--color-focus);
  }
  @media only screen and (max-width: 39.9375rem), print {
    .gen h1 {
      font-size: var(--typography--fontSize-largest);
      margin-bottom: var(--space-base);
    }
  }
  @media print {
    .gen a {
      color: inherit;
      text-decoration: none;
    }
  }
  .gen :target {
    box-shadow: var(--shadow-focus);
  }
}
