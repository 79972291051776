th {
  --resizeWidth: 8px;
}

.trunc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.headDND {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - var(--resizeWidth));
  height: 100%;
  cursor: move;
}

.disabledDND {
  cursor: default;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
  height: 100%;
  cursor: col-resize;
  background: gray;
  opacity: 0;
}

th:hover .resizer {
  opacity: 0.2;
}

th:hover .isResizing {
  opacity: 1;
}

.isResizing {
  opacity: 1;
  background: var(--green);
}

#newTable>.rc-handle-container {
  display: none;
}

.gridTable {
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.8rem
}

/* .gridTable>* {
  padding: 0.8rem;
  box-sizing: border-box;
  border: 1px solid #7d7d7d;
}

.gridTable>*:not(:nth-child(2n + 1)) {
  border-left: none;
}

.gridTable>*:not(:nth-last-child(-n+2)) {
  border-bottom: none;
} */
