.iconContainer {
	position: absolute;
	right: 10px;
	top: 10%;
	padding: 10px;
	display: flex;
	align-items: center;
    border-radius: 5px;
	gap: 10px; /* Adjust the gap between icons if needed */
}

.table-hover > tbody > tr:hover > .iconContainer {
	background-color: white !important;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	--bs-table-bg-state: white !important;
}

.iconContainer svg:hover{
	background-color: #F4F6FA;
	
}