/* Overall calendar styling */
.fc {
	max-width: 100%;
	margin: 0 auto;
	border-radius: 10px;
	padding: 20px;
}

/* Header styling */
.fc-toolbar {
	background: rgba(255, 255, 255, 0.1);
	border-radius: 5px;
	margin-bottom: 10px;
}

/* Day grid styling */
.fc-daygrid-day {
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.fc-daygrid-day-top {
	color: white;
}

/* Event styling */
.highlight-translated {
	background-color: #0DCAF0 !important; /* Deep Sky Blue */
	color: black;
}

.highlight-updated {
	background-color: #198754 !important; /* Lime Green */
}

.highlight-corrected {
	background-color: #DC3545 !important; /* Orange Red */
	color: white;
}

.highlight-all {
	background-color: #808080 !important; /* Grey */
}

/* Legend styling */
.legend {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
}

.legend-item {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.legend-item span {
	display: inline-block;
	width: 15px;
	height: 15px;
	margin-right: 5px;
	border-radius: 3px;
}

/* Calendar container styling */
.fc-daygrid {
	height: 75vh; /* Adjust height as needed */
	overflow-y: auto;
}

.legend__active{
    font-weight: 800;   
    text-decoration: underline;
}